import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { unsubBulkApi } from "../Data/Api";
import Post from "../Requests/Post";
import { options } from "../Data/LoaderData";

let token;
const BulkUnsub = () => {

  //to call on start
  useEffect(()=>{
    checkToken();
  },[]);

  //to go on other page
  const navigate=useNavigate();

  //Hook to store check value
  const[check,setCheck]=useState(false);

  //Hook to store file path
  const[filePath,setFilePath]=useState('');

  //Hook to store file type
  const[type,setType]=useState('');

  //Method to handle select file
  const handleSelectedFile=(e)=>{
    //console.log(e);
    setFilePath(e);
  }

  //Method to handle tick box value
  const handleCheckBox=(e)=>{
    //console.log(e);
    setCheck(!check);
  }

  //Method to handle confirm button
  const confirmButton=()=>{
    //console.log("check ",check);
    //console.log("file ",filePath);

    if(type===null || type==='' || type===undefined)
    {
      toast.error("Add File First");
    }
    else
    {
      hitOnBackend();
      setDivState('block');  
    }
  }

  //Method to hit on backend
  const hitOnBackend=()=>{
    let formData=new FormData();
    formData.append("file",filePath);
    formData.append("check",check);
    formData.append("user",localStorage.getItem("user"));
    formData.append("type",type);

    let promise=Post(unsubBulkApi,formData);
    promise.then(e=>{
      handleResponse(e);
    })
  }

  //Method to handle response
  const handleResponse=(e)=>{
    //console.log("e ",e);
    setDivState('none'); //hiding loader

    if(e.response==='error')
    {
      navigate("/error");
    }
    else if(e.response==='0')
    {
      toast.error("Something Went Wrong");
    }
    else if(e.response==='1')
    {
      toast.success(e.message);
    }
  }

//Loader Stuff
let Loader=require('react-loader');

//Hook to store starting div state(loading div)
const[divState,setDivState]=useState('none');

//check token from local storage
const checkToken=()=>{
  token=localStorage.getItem("token");
}

if(token===null||token===undefined||token===''||token===' ')
{
  setTimeout(()=>{
    if(token===undefined || token==null ||token===''||token===' ')
    {
      navigate("/");
    }
  },1000/5);
}

  return (
    <>
      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={false} options={options} className="spinner" />
      </div>

      {/* <!-- sec5 --> */}
      <ToastContainer/>
      <Header/>
      <Sidebar/>
      <div id="fiveTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Bulk Unsubscribe</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <div className="main-date-form">
            {/* <!-- user --> */}
            <div className="c-box">
              <div className="title-ic">
                <p>
                  <span>
                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                  </span>{" "}
                  <span>Bulk Unsubscribe</span>
                </p>
              </div>

              {/* <!-- date --> */}
              <div className="date-inner">
                <h5 style={{fontFamily:"serif"}}>Using Text File</h5>
                <div className="end-date">
                  <label htmlFor="myfile">Msisdn File (.txt):</label>
                  <input type="file" id="myfile" name="myfile" accept="text/plain" 
                    onChange={(e)=>{
                      handleSelectedFile(e.target.files[0]);
                      setType('text');
                    }}/>
                </div>
                <div>
                  <label htmlFor="mySelect">Check to Add in DND:</label>
                  <input type="checkbox" id="mySelect"
                    onChange={(e)=>{
                      handleCheckBox(e.target.value);
                    }}/>
                </div>
                <div className="text-file-p">
                  <p>
                    Only .txt file is allowed and each line should contain only
                    one MSISDN.
                  </p>
                </div>
                <div className="date-search-btn">
                  <button className="bg-orange"
                    onClick={()=>{
                      confirmButton();
                    }}>
                    Confirm
                  </button>
                </div>
              </div>

               <hr/>

              {/* <!-- date 2 --> */}
              <div className="date-inner">
              <h5 style={{fontFamily:"serif", marginTop:"10px"}}>Using Excel File</h5>
                <div className="end-date">
                  <label htmlFor="myfile">Msisdn File (.xlsx):</label>
                  <input type="file" id="myfile" name="myfile" accept=".xlsx" 
                    onChange={(e)=>{
                      handleSelectedFile(e.target.files[0]);
                      setType('excel');
                    }}/>
                </div>
                <div>
                  <label htmlFor="mySelect">Check to Add in DND:</label>
                  <input type="checkbox" id="mySelect"
                    onChange={(e)=>{
                      handleCheckBox(e.target.value);
                    }}/>
                </div>
                <div className="text-file-p">
                  <p>
                    Only .xlsx file is allowed and there should be single column only without heading
                  </p>
                </div>
                <div className="date-search-btn">
                  <button className="bg-orange"
                    onClick={()=>{
                      confirmButton();
                    }}>
                    Confirm
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BulkUnsub;
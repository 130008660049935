// const pre=`http://localhost:2942/`;
// const pre=`http://5.189.166.187:5551/AzmoonCCPortalBackendLive/`;
const pre=`https://azmoonbackend.thehappytubes.com/`;

const checkApi=`${pre}open/check`;
export{checkApi};

const sendSubscribersApi=`${pre}secure/sendSubscribers`;
export{sendSubscribersApi};

const sendSubscriberApi=`${pre}secure/sendSubscriber`;
export{sendSubscriberApi};

const subscribeApi=`${pre}secure/subscribe`;
export{subscribeApi};

const unsubApi=`${pre}secure/unsubscribe`;
export{unsubApi};

const unsubBulkApi=`${pre}secure/unsubBulk`;
export{unsubBulkApi};

const updateCredentialsApi=`${pre}secure/updateCredentials`;
export{updateCredentialsApi};

const sendUsersApi=`${pre}sendUsers`;
export{sendUsersApi};

const updateUserCredentialsApi=`${pre}updateUserCredentials`;
export{updateUserCredentialsApi};

const addNewUserApi=`${pre}addNewUser`;
export{addNewUserApi};
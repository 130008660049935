import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { unsubApi } from "../Data/Api";
import Post from "../Requests/Post";
import { options } from "../Data/LoaderData";

let token;
const Unsub = () => {

  //To call on load
  useEffect(()=>{
    checkToken();
  },[]);

  //to go on any other page
  const navigate=useNavigate();

  //Hook to store ani & pack
  const[ani,setAni]=useState('');

  //Method to handle activation button
  const handleDeactivationButton=()=>{
    checkingEmptyValues();
  }

  //Method to check empty values
  const checkingEmptyValues=()=>{

    let result=true;
    if(ani==='' || ani===' ' || ani===null || ani===undefined)
    {
      toast.error("Enter MSISDN First !!");
      result=false;
    }

    if(result===true)
    {
      hitOnBackend();
      setDivState('block');
    }
  }

  //Method to hit on backend for subscription
  const hitOnBackend=()=>{
    let request={ani:ani,user:localStorage.getItem("user")};
    //console.log("request ",request);

    let promise=Post(unsubApi,request);
    promise.then(e=>{
      handleResponse(e);
    })
  }

  //Method to handle response
  const handleResponse=(e)=>{
    //console.log(e);
    setDivState('none');//hiding loader
    
    if(e.response==='error')
    {
      navigate("/error");
    }
    else if(e.response==='0')
    {
      toast.error("Something Went Wrong");
    }
    else if(e.response==='1')
    {
      toast.success(e.message);
    }
  }

//Loader Stuff
let Loader=require('react-loader');

//Hook to store starting div state(loading div)
const[divState,setDivState]=useState('none');

//check token from local storage
const checkToken=()=>{
  token=localStorage.getItem("token");
}

if(token===null||token===undefined||token===''||token===' ')
{
  setTimeout(()=>{
    if(token===undefined || token==null ||token===''||token===' ')
    {
      navigate("/");
    }
  },1000/5);
}

  return (
    <>

      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={false} options={options} className="spinner" />
      </div>
      
    <ToastContainer/>
    <Header/>
    <Sidebar/>
      <div id="fourTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Unsubscribe</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <div className="main-date-form">
            {/* <!-- user --> */}
            <div className="c-box">
              <div className="title-ic">
                <p>
                  <span>
                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                  </span>{" "}
                  <span>Unsubscribe</span>
                </p>
              </div>

              {/* <!-- date --> */}
              <div className="date-inner">
                <div className="end-date">
                  <label htmlFor="name">Msisdn:</label>
                  <input
                    type="name"
                    id="name"
                    name="name"
                    placeholder="Msisdn"
                    autoComplete="off"
                    onChange={(e)=>{
                      setAni(e.target.value);
                    }}
                  />
                </div>
                <div className="date-search-btn">
                  <button className="bg-orange" 
                     onClick={()=>{
                      handleDeactivationButton();
                    }}>
                    Deactivate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Unsub;
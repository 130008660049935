import React from "react";
import {BrowserRouter,Routes,Route} from "react-router-dom"
import Activation from "../Pages/Activation";
import BulkUnsub from "../Pages/BulkUnsub";
import ChangePassword from "../Pages/ChangePassword";
import ErrorPage from "../Pages/Error";
import Login from "../Pages/Login";
import PageNotFound from "../Pages/PageNotFound";
import Search from "../Pages/Search";
import Subscribers from "../Pages/Subscribers";
import Unsub from "../Pages/Unsub";
import Users from "../Pages/User";

const Routing=(role)=>{
    console.log("Inside Routes ",role);
    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<Login/>} path="/" exact={true}></Route>
                    <Route element={<Subscribers/>} path="/subscribers" exact={true}></Route>
                    <Route element={<Search/>} path="/search" exact={true}></Route>
                    <Route element={<Activation/>} path="/activation" exact={true}></Route>
                    <Route element={<Unsub/>} path="/unsub" exact={true}></Route>
                    <Route element={<BulkUnsub/>} path="/bulkUnsub" exact={true}></Route>
                    <Route element={<ChangePassword/>} path="/changePassword" exact={true}></Route>
                    <Route element={<PageNotFound/>} path="*" exact={true}></Route>
                    <Route element={<ErrorPage/>} path="/error" exact={true}></Route>
                    {role.role==='Super'&&(
                    <Route element={<Users/>} path="/users" exact={true} ></Route>
                    )}
                </Routes>
            </BrowserRouter>
        </>
    );
}
export default Routing;
import './App.css';
import Routing from './Routes/Routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "./CSS/style.css";
import { useEffect, useState } from 'react';

function App() {
  
  //To run on load
  useEffect(()=>{
    checkUserType();
    // eslint-disable-next-line
  },[]);

  //Hook to store role
  const[role,setRole]=useState('Admin');

  //To Check Type of User
  const checkUserType=()=>{
    let role=localStorage.getItem("role");
    // console.log("Inside App.js role ",role);

    setRole(role);
} 
  return (
   <Routing role={role}/>
  );
}

export default App;
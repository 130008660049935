import axios from "axios";

const Post=async(url,request)=>{

    let apiResponse;

    await axios.post(url,request)
    .then(
        (response)=>{
            // console.log(response);
            apiResponse=response.data;
        },
        (error)=>{
            console.log(error);
            let r={"response":"error"};
            apiResponse=r;
        }
    )
    return apiResponse;
}
export default Post;
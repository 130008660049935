import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendSubscribersApi } from "../Data/Api";
import Post from "../Requests/Post";

let token;
const Subscribers = () => {

  //to go on any other page in this application
  const navigate=useNavigate();

  //Run first on page load
  useEffect(()=>{
    checkToken();
    getSubscribersData();
    // eslint-disable-next-line
  },[]);

  //Method to get Subscribers data from backend
  const getSubscribersData=()=>{

    //End Date
    let currentDate=new Date();
    let endDate=getDate("to",currentDate);
    //console.log("endDate ",endDate);

    //from Date
    let fromDate=new Date();
    fromDate.setDate(fromDate.getDate()-30);
    let startDate=getDate("from",fromDate);
    //console.log("startDate ",startDate);

    //Setting by default Dates
    setDates({...dates,startDate:startDate,endDate:endDate});

    let request={startDate:startDate,endDate:endDate,size:tableSize,currentPage:0}
    hitOnBackend(request);
  }

  //Method to return date in proper format according to backend
  const getDate=(type,date)=>{
    let year=date.getFullYear();
    let month=date.getMonth()+1<10?"0".concat(date.getMonth()+1):date.getMonth()+1;
    let day=date.getDate()<10?"0".concat(date.getDate()):date.getDate();

    let minutes;
    if(type==='to')
    {
      minutes="23:59:59";
    }
    else if(type==='from')
    {      
      minutes="00:00:00";
    }

    let getDate=year+"-"+month+"-"+day +" "+minutes;
    return getDate;
  }

  //Hook to store subscribers data
  const[data,setData]=useState([]);
  const[dataa,setDataa]=useState([]);

  //Hook to store pagination Data
  const[currentPage,setCurrentPage]=useState(0);
  const[numberOfTotalElement,setNumberOfElement]=useState(0);
  // const[totalPages,setTotalPages]=useState(0);
  const[totalElements,setTotalElement]=useState(0);

  //Hook to store start date & end end date
  const[dates,setDates]=useState({});

  //Hook to store buttons state (next button, previous button in pagination)
  const[nextButton,setNextButton]=useState(false);
  const[previousButton,setPreviousButton]=useState(false);

  //Hook to store size of table rows
  const[tableSize,setTableSize]=useState(10);

  //Method to hit on backend & get subscribers data
  const hitOnBackend=(request)=>{
    let promise=Post(sendSubscribersApi,request);
    promise.then(e=>{
      //console.log("e ",e);
      handlingResponse(e);
    })
  }

  //Method to handle Backend Response
  const handlingResponse=(e)=>{
    if(e.response==='error')
    {
      navigate("/error");
    }
    else if(e.status==='2')
    {
      //Date Format is Incorrect
      toast.error("Incorrect Date Format");
    }
    else if(e.status==='3')
    {
      //Something Went Wrong
      toast.error("Something Went Wrong");
    }
    else if(e.status==='1')
    {
      //Data Fetch successfully
      setData(e.data);
      setDataa(e.data);
      setCurrentPage(e.currentPage);
      setNumberOfElement(e.numberOfElement);
      // setTotalPages(e.totalPages);
      setTotalElement(e.totalElements);
      settingButtonStates(e.currentPage,e.totalPages);
    }
  }

  //Setting next,previous button states
  const settingButtonStates=(currentPage,totalPages)=>{
    //console.log("currentPage ",currentPage);
    //console.log("totalElements ",totalPages);

    if(currentPage===0)
    {
      //console.log("previous if");
      setPreviousButton(true);
    }
    else
    {
      //console.log("previous else");
      setPreviousButton(false);
    }

    if(currentPage===totalPages)
    {
      //console.log("next if");
      setNextButton(true);
    }
    else
    {
      //console.log("next else");
      setNextButton(false);
    }
  }

  //Method to handle Search button (search by dates)
  const handleSearchButton=()=>{
    //console.log("Dates are ",dates);

    //Getting Data from backend
    let request={startDate:dates.startDate,endDate:dates.endDate,size:tableSize,currentPage:0};
    hitOnBackend(request);
  }

  //Method to Handle pagination
  const handlePagination=(page)=>{
    //console.log("Required Page is ",page);
    //console.log("Dates ",dates);

    //Getting Data from backend
    let request={startDate:dates.startDate,endDate:dates.endDate,size:tableSize,currentPage:page};
    hitOnBackend(request);
  }

  //Method to handle search box
  const handleSearchBox=(e)=>{

    let tempArray=[];
    dataa.forEach((m)=>{
      // //console.log("m ",m);
      if(m.ani.startsWith(e))
      {
        tempArray.push(m);
      }
    })
    setData(tempArray);
  }

  //Method to set Table Size
  const handleTableSize=(e)=>{
    //console.log("tableSize ",Number(e));
    setTableSize(Number(e));

    //Getting Data from backend
    let request={startDate:dates.startDate,endDate:dates.endDate,size:Number(e),currentPage:0};
    hitOnBackend(request);
  }

   //check token from local storage
   const checkToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===null||token===undefined||token===''||token===' ')
  {
    setTimeout(()=>{
      if(token===undefined || token==null ||token===''||token===' ')
      {
        navigate("/");
      }
    },1000/5);
  }

  return (
    <>
      {/* <!-- subscribers-sec --> */}
      <ToastContainer/>
      <Header />
      <Sidebar />
      <div id="firstTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Subscribers</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <div className="main-date-form ss">
            {/* <!-- date --> */}
            <div className="date-inner date-1-sec">
              <div className="end-date">
                <label htmlFor="start">Start date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={(e)=>{
                    setDates({...dates,startDate:e.target.value.concat(" 00:00:00")});
                  }}
                />
              </div>
              <div className="end-date">
                <label htmlFor="start">End date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={(e)=>{
                    setDates({...dates,endDate:e.target.value.concat(" 23:59:59")});
                  }}
                />
              </div>
              <div className="date-search-btn">
                <button type="submit" onClick={()=>{
                  handleSearchButton();
                }}>Search</button>
              </div>
            </div>

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Subscribers</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">
              <div className="nomber-slect">
                <div className="slect-no">
                  <select name="cars" id="cars" form="carform" onChange={(e)=>{
                    handleTableSize(e.target.value);
                  }}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="s-data">
                  <input type="text" placeholder="Enter MSISDN Here" onChange={(e)=>{
                    handleSearchBox(e.target.value);
                  }}/>
                </div>
              </div>

              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                  <tr>
                      <th>S.No</th>
                      <th>Msisdn</th>
                      <th>Status</th>
                      <th>Subscription Date</th>
                      <th>Mode</th>
                    </tr>
                    {
                      data.length>0 && data.map((value,index)=>{
                        return(
                          <tr key={index}>
                          <td>{index+1}</td>
                          <td>{value.ani}</td>
                          <td>{value.status==='Active'?value.status:"Low Balance"}</td>
                          <td>{value.subDateTime}</td>
                          <td>{value.mact}</td>
                        </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>

              {/* <!-- pag --> */}
              <div className="pagination-sec">
                <div className="add-pagination">
                  <p>
                    Showing <span>{((currentPage+1)*numberOfTotalElement)-numberOfTotalElement+1}</span> to <span>{(currentPage+1)*numberOfTotalElement}</span> of <span>{totalElements}</span>{" "}
                    entries
                  </p>
                </div>
                <div className="pagination-next">
                  <ul className="pagination">
                    <li className="page-item">
                      <button className="page-link" disabled={previousButton}
                        onClick={()=>{
                          handlePagination(currentPage-1);
                        }}>
                        Previous
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link"
                        onClick={()=>{
                          handlePagination(0);
                        }}>
                        First
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link"
                        onClick={()=>{
                          handlePagination(currentPage);
                        }}>
                        {currentPage+1}
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link" 
                        onClick={()=>{
                          handlePagination(currentPage+1);
                        }}>
                      {currentPage+2}
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link"
                        onClick={()=>{
                          handlePagination(currentPage+2);
                        }}>
                      {currentPage+3}
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link"
                        disabled={nextButton}
                        onClick={()=>{
                          handlePagination(currentPage+1);
                        }}>
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Subscribers;
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Images/logo.jpg";

const Header = () => {
  //to go on other page
  const navigate = useNavigate();

  //Method to handle logout button
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <header className="main-header">
        <div className="top-header">
          <div className="top-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="top-header-logout">
            <div
              className="date-search-btn"
              onClick={() => {
                handleLogout();
              }}
            >
              <button className="bg-orange">
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {

  //Use to send on other route/page
  const navigate=useNavigate();

  //Method to handle navigation of sidebar
  const handleNavigate=(route)=>{
    navigate(route);
  }

  //To run on load
  useEffect(()=>{
    checkUserType();
  },[]);

  const[display,setDisplay]=useState('none');

  //To Check Type of User
  const checkUserType=()=>{
    let role=localStorage.getItem("role");
    // console.log("Inside sidebar role ",role);
    if(role==='Super')
    {
      setDisplay('block');
    }
  } 

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-inner">
          <p>MAIN</p>
        </div>
        {/* <!-- 1 --> */}
        <button
          className="tablinks"
          onClick={()=>{
            handleNavigate("/subscribers");
          }}
          id="defaultOpen"
        >
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Subscribers</span>
        </button>
        {/* <!-- 2 --> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/search");
        }}>
          {" "}
          <span>
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>{" "}
          <span>Search</span>
        </button>
        {/* <!-- 3 --> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/activation");
        }}>
          <span>
            <i className="fa fa-plus" aria-hidden="true"></i>
          </span>{" "}
          <span> Activation</span>
        </button>
        {/* <!-- 4 --> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/unsub");
        }}>
          <span>
            <i className="fa fa-times-circle-o" aria-hidden="true"></i>
          </span>{" "}
          <span>Unsub Manually</span>
        </button>
        {/* <!-- 5 --> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/bulkUnsub");
        }}>
          <span>
            <i className="fa fa-times-circle-o" aria-hidden="true"></i>
            <i className="fa fa-times-circle-o" aria-hidden="true"></i>
          </span>{" "}
          <span>Bulk Unsub</span>
        </button>
        {/* <!-- 6 --> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/changePassword");
        }}>
          <span>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </span>{" "}
          <span> Change Password</span>
        </button>
        {/* <!-- 6 --> */}
        <button 
        style={{display:`${display}`}}
        className="tablinks" onClick={()=>{
          handleNavigate("/users");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Users</span>
        </button>
      </div>
    </>
  );
};
export default Sidebar;
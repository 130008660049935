import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendUsersApi,updateUserCredentialsApi,addNewUserApi } from "../Data/Api";
import Get from "../Requests/Get";
import Post from "../Requests/Post";

const Users = () => {

//To Load on Start
useEffect(()=>{
    getUsersFromBackend();
    // eslint-disable-next-line
},[]);

//Method to hit on backend
const getUsersFromBackend=()=>{
    let promise=Get(sendUsersApi);
    promise.then(e=>{
        handleResponse(e);
    })
}

//Hook to store users
const[users,setUsers]=useState([]);

//Method to Handle Response
const handleResponse=(e)=>{
    // console.log("e ",e);

    if(e.response==='3')
    {
        toast.error("Network Error");
    }
    else if(e.response==='0')
    {
        toast.error("Something Went Wrong");
    }
    else if(e.response==='1')
    {
        setUsers(e.data);
    }
    else
    {
        toast.error("Something Went Wrong");
    }
}

//Hook to store user data
const[data,setData]=useState({});

//Method to handle form
const handleFormSubmit=(e)=>{
    e.preventDefault();
    // console.log("data ",data);
    hitOnBackendToUpdate();
}

//Method to hit on backend to update password
const hitOnBackendToUpdate=()=>{
    let promise=Post(updateUserCredentialsApi,data);
    promise.then(e=>{
        handleUpdateResponse(e);
    })
}

//Method to handle update Response
const handleUpdateResponse=(e)=>{
    // console.log("e ",e);

    if(e.response==='3')
    {
        toast.error("Network Error");
    }
    else if(e.response==='0')
    {
        toast.error("Something Went Wrong");
    }
    else if(e.response==='1')
    {
        toast.success(e.message);
        getUsersFromBackend();
    }
    else
    {
        toast.error("Something Went Wrong");
    }
}

//Method to add New User
const handleFormSubmitNewUser=(e)=>{
    e.preventDefault();
    // console.log("data ",data);

    let promise=Post(addNewUserApi,data);
    promise.then(e=>{
        // console.log("e ",e);

        if(e.response==='3')
        {
            toast.error("Network Error");
        }
        else if(e.response==='0')
        {
            toast.error("Something Went Wrong");
        }
        else if(e.response==='1')
        {
            toast.success(e.message);
            getUsersFromBackend();
        }
        else
        {
            toast.error("Something Went Wrong");
        }
    })
}

  return (
    <>
      {/* <!-- subscribers-sec --> */}
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="firstTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Users</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <div className="main-date-form ss">

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Users</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">
              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                    <tr>
                      <th>S.No</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Creation Date</th>
                      <th>Action</th>
                    </tr>
                   {
                    users.map((value,key)=>{
                        return(
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td><input type="text" defaultValue={value.username} readOnly/></td>
                                <td><input type="text" defaultValue={value.password} readOnly/></td>
                                <td>{value.dateTime}</td>
                                <td><span style={{color:"darkorange",cursor:"pointer"}}
                                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                                        onClick={()=>{
                                            // console.log(value);
                                            setData({...data,username:value.username,password:value.password});
                                        }}
                                       >
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                </td>
                            </tr>
                        )
                    })
                   }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="date-search-btn"
        style={{textAlign:"center",marginTop:"2px"}}>
        <button className="bg-orange" 
            data-bs-toggle="modal" data-bs-target="#exampleModal2"
        >
            Add New User
        </button>
        </div>

      {/* Modal */}

      {/* <!-- Button trigger modal --> */}
        {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
        </button> */}

        {/* <!-- Modal --> */}
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Update User Credentials</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleFormSubmit}>
                    <div className="date-inner">
                    <div className="end-date">
                    {/* <label htmlFor="name">Username:</label> */}
                    <input
                        type="name"
                        id="name"
                        name="name"
                        placeholder="Username"
                        readOnly
                        autoComplete="off"
                        defaultValue={data.username}
                        onChange={(e)=>{
                        setData({...data,username:e.target.value});
                        }}
                    />
                    </div>

                    <div className="end-date">
                    {/* <label htmlFor="name">Password:</label> */}
                    <input
                        type="name"
                        id="name"
                        name="name"
                        placeholder="Password"
                        autoComplete="off"
                        defaultValue={data.password}
                        onChange={(e)=>{
                        setData({...data,password:e.target.value});
                        }}
                    />
                    </div>

                    <div className="date-search-btn">
                    <button className="bg-orange" 
                        onClick={()=>{
                        }}>
                        Update
                    </button>
                    </div>
                </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>

        {/* <!-- Modal2 --> */}
        <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Add New User</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleFormSubmitNewUser}>
                    <div className="date-inner">
                    <div className="end-date">
                    {/* <label htmlFor="name">Username:</label> */}
                    <input
                        type="name"
                        id="name"
                        required
                        name="name"
                        placeholder="Username"
                        autoComplete="off"
                        onChange={(e)=>{
                        setData({...data,username:e.target.value});
                        }}
                    />
                    </div>

                    <div className="end-date">
                    {/* <label htmlFor="name">Password:</label> */}
                    <input
                        type="name"
                        id="name"
                        name="name"
                        placeholder="Password"
                        autoComplete="off"
                        required
                        onChange={(e)=>{
                        setData({...data,password:e.target.value});
                        }}
                    />
                    </div>

                    <div className="date-search-btn">
                    <button className="bg-orange" 
                       >
                        Add
                    </button>
                    </div>
                </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>
    </>
  );
};
export default Users;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendSubscriberApi } from "../Data/Api";
import Post from "../Requests/Post";
import { options } from "../Data/LoaderData";

let token;
const Search = () => {
  //to call checkToken() on start
  useEffect(() => {
    checkToken();
  }, []);

  //to go on other page
  const navigate = useNavigate();

  //Hook to store ani
  const [ani, setAni] = useState("");

  //hook to store other data
  const[info,setInfo]=useState({});

  //Hook to store sub data
  const [sub, setSub] = useState({});

  //Hook to store unsub data
  const [unsub, setUnsub] = useState([]);

  //Method to handle the search button
  const handleSearchButton = () => {
    //console.log("ani ",ani);
    setDivState("block");
    sendRequestToBackend();
  };

  //Method to send request on backend
  const sendRequestToBackend = () => {
    let request = { ani: ani };
    let promise = Post(sendSubscriberApi, request);
    promise.then((e) => {
      handleResponse(e);
    });
  };

  //Method to handle response
  const handleResponse = (e) => {
    console.log("e ",e);
    // console.log(e.billingCount);
    setDivState("none"); //hiding loader
    setDisplay("block"); //showing stepwise logs button

    if (e.response === "5") {
      navigate("/error");
    } else if (e.response === "0") {
      //Something Went Wrong
      toast.error("Something Went Wrong");
    } else if (e.response === "2") {
      //No Data Found
      toast.success("No Data Found");
    } else if (e.response === "1") {
      //Data Found
      setSub(e.sub);
      setUnsub(e.unsub);

      setInfo({...info,
                        selectPackMessage:e.selectPackMessage,
                        dailyOption:e.dailyOption,
                        weeklyOption:e.weeklyOption,
                        monthlyOption:e.monthlyOption,
                        subscribeMessage:e.subscribeMessage,
                        successMessage:e.successMessage
                      })

      document.getElementById("billingCount").value = e.billingCount;
    }
  };

  //Hook to store stepwise logs button visibility
  const [display, setDisplay] = useState("none");

  //Loader Stuff
  let Loader = require("react-loader");

  //Hook to store starting div state(loading div)
  const [divState, setDivState] = useState("none");

  //check token from local storage
  const checkToken = () => {
    token = localStorage.getItem("token");
  };

  if (token === null || token === undefined || token === "" || token === " ") {
    setTimeout(() => {
      if (
        token === undefined ||
        token == null ||
        token === "" ||
        token === " "
      ) {
        navigate("/");
      }
    }, 1000 / 5);
  }

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={false} options={options} className="spinner" />
      </div>

      {/* <!-- sec2 --> */}
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Search</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <div className="main-date-form">
            {/* <!-- user --> */}
            <div className="title-ic">
              <div>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Search Here</span>
                <span style={{ float: "right", display: `${display}` }}>
                  <span className="date-search-btn-my">
                    <button
                      className="bg-orange"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      + Stepwise Logs
                    </button>
                  </span>
                </span>
              </div>
            </div>

            {/* <!-- date --> */}
            <div className="date-inner">
              <div className="end-date">
                <label htmlFor="name">Msisdn:</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Msisdn"
                  onChange={(e) => {
                    setAni(e.target.value);
                  }}
                />
              </div>
              <div className="date-search-btn">
                <button
                  className="bg-orange"
                  onClick={() => {
                    handleSearchButton();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">
              <div className="subscription-t2">
                <p>Subscription</p>
              </div>

              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                    <tr>
                      <th>Msisdn</th>
                      <th>Status</th>
                      <th>Subscription Date</th>
                      <th>Subscribe Mode</th>
                      <th>Pack</th>
                      <th>Price</th>
                    </tr>
                    <tr>
                      <td>{sub === null ? "" : sub.ani}</td>
                      <td>{sub === null ? "" : sub.status}</td>
                      <td>{sub === null ? "" : sub.subDateTime}</td>
                      <td>{sub === null ? "" : sub.mact === "Promo" ||
                                               sub.mact === "Migration"
                                              ? "USSD"
                                              : sub.mact}</td>
                      <td>{sub === null ? "" : sub.pack}</td>
                      <td>{sub === null ? "" : sub.amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* <!-- table --> */}
              <div className="unSubscription-table mt-3">
                <div className="unSubscription">
                  <p>UnSubscription</p>
                </div>

                <div
                  className="table-sec"
                  style={{ maxHeight: "700px", overflowY: "scroll" }}
                >
                  <table className="main-table">
                    <tbody>
                      <tr>
                        <th>S.No</th>
                        <th>Msisdn</th>
                        <th>Unsubscribe Date</th>
                        <th>Subscribe Date</th>
                        <th>Subscribe Mode</th>
                        <th>Unsubscribe Mode</th>
                        <th>Pack</th>
                        <th>Price</th>
                      </tr>
                      {unsub.length > 0 &&
                        unsub.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{value.ani}</td>
                              <td>{value.unsubDateTime}</td>
                              <td>{value.subDateTime}</td>
                              <td>
                                {value.mact === "Promo" ||
                                value.mact === "Migration"
                                  ? "USSD"
                                  : value.mact}
                              </td>
                              <td>{value.mdeact}</td>
                              <td>{value.pack}</td>
                              <td>
                                {value.pack === "Daily"
                                  ? "4"
                                  : value.pack === "Weekly"
                                  ? "15"
                                  : value.pack === "Monthly"
                                  ? "30"
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="date-inner"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div className="end-date">
            <label htmlFor="name">BillingCount:</label>
            <input
              type="text"
              id="billingCount"
              placeholder="Billing Count"
              readOnly
              style={{ marginLeft: "5px" }}
            />
          </div>
        </div>
      </div>

      {/* Bootstrap Modal */}

      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button> */}

      <div
        className="modal modal-lg"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">

              <>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    Step 1
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    [{sub===null?ani:sub.ani}], Resp [Please select your language
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    1. English
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    2. پښتو
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                   <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    3. دري
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    Step 2 Customer selected {sub===null?"":sub.language}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    Trans Id [{sub===null?"":sub.txnId}]
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    Step 3 Customer Selected Package
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    [{sub===null?ani:sub.ani}], Resp [{info.selectPackMessage}]
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    1. {info.dailyOption}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    2. {info.weeklyOption}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <br/>
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    3. {info.monthlyOption}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
 
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    Step 4 Customer Selected {sub===null?"":sub.pack} [Confirmation of Selected Package]
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    [{sub===null?ani:sub.ani}], Resp [{info.subscribeMessage}], Trans Id [412028562491966]
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    Step 5 Success Message
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: 15,
                    fontFamily: '"Calibri","sans-serif"',
                  }}
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontFamily: '"Tahoma","sans-serif"',
                      color: "#262626",
                    }}
                  >
                    [{sub===null?ani:sub.ani}], Resp[{info.successMessage}]
                  </span>
                </p>
              </>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Search;
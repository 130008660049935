import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import logo from "../Images/logo.jpg";
import { checkApi } from "../Data/Api";
import Post from "../Requests/Post";
import { options } from "../Data/LoaderData";

const Login = () => {

  //to go on other page
  const navigate=useNavigate();

  //To handle login button
  const handleLoginButton=()=>{

    //Getting Credentials
    //console.log("Credentials ",credentials);

    //Checking Credentials
    checkingBlankValues();
  }

  //Method to check blank or null values
  const checkingBlankValues=()=>{

    let usernameResult=true;
    let passwordResult=true;

    if(credentials.username===''||credentials.username===' '
        ||credentials.username===null||credentials.username===undefined)
        {
          //console.log("Please Enter Username");
          toast.error("Enter Username First !!");
          usernameResult=false;
        }
    if(credentials.password===''||credentials.password===' '
        ||credentials.password===null||credentials.password===undefined)
        {
          //console.log("Please Enter Password");
          toast.error("Enter Password First !!");
          passwordResult=false;
        }

    if(usernameResult===true && passwordResult===true)
    {
      //Can send to check Credentials
      //console.log("Valid");
      matchCredentials();
      setDivState('block');
    }
    else
    {
      //not correct
      //console.log("Not Valid");
    }
  }

  //Method to match credentials from backend
  const matchCredentials=()=>{
      let promise=Post(checkApi,credentials);
      promise.then(e=>{
        handleApiResponse(e);
      })
  }

  //Method to Handle Api Response
  const handleApiResponse=(e)=>{
    //console.log("e ",e);
    setDivState('none');
    
    if(e.response==='error')
    {
      navigate("/error");
    }
    else if(e.response===false)
    {
      //Wrong Credentials
      //console.log("Wrong Credentials");
      toast.error(e.description);
    }
    else if(e.response===true)
    {
      //Correct Credentials
      //console.log("Correct Credentials");
      localStorage.setItem("token","sJKWER@#$#JKZ");
      localStorage.setItem("role",e.type);
      localStorage.setItem("user",credentials.username);
      navigate("/subscribers");
    }
  }

  //Hook to store user credentials
  const[credentials,setCredentials]=useState({});

  //Loader Stuff
  let Loader=require('react-loader');

  //Hook to store starting div state(loading div)
  const[divState,setDivState]=useState('none');

  return (
    <>
      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={false} options={options} className="spinner" />
      </div>

      <ToastContainer/>
      <section className="login-sec">
        <div className="login-heading">
          <h2>MBT Azmoon CC Panel</h2>
        </div>
        <div className="login-box">
          <div className="login-inner-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="login-form">
            <div className="form">
              <div className="succe-massge">
                <p>Enter Details Here</p>
              </div>
              <div className="user">
                <span className="icon-u">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>
                <span>
                  <input
                    type="text"
                    placeholder="Username"
                    name="username"
                    required
                    autoComplete="off"
                    onChange={(e)=>{
                      setCredentials({...credentials,username:e.target.value});
                    }}
                  />
                </span>
              </div>
              <div className="password">
                <span className="icon-l">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
                <span>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    required
                    autoComplete="off"
                    onChange={(e)=>{
                      setCredentials({...credentials,password:e.target.value});
                    }}
                  />
                </span>
              </div>
              <div className="submit-btn" 
              onClick={()=>{
                handleLoginButton();
              }}>
                <button type="submit">
                  <span>
                    <i className="fa fa-sign-in" aria-hidden="true"></i>
                  </span>{" "}
                  <span>Login</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
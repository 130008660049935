import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { updateCredentialsApi } from "../Data/Api";
import Post from "../Requests/Post";
import { options } from "../Data/LoaderData";

let token;
const ChangePassword = () => {

  //to call on load
  useEffect(()=>{
    checkToken();
  },[]);

  //to go on other page
  const navigate=useNavigate();

  //Hooks to store passwords
  const[oldPassword,setOldPassword]=useState('');
  const[newPassword,setNewPassword]=useState('');

  //Method to handle change button
  const handleChangeButton=()=>{
    //console.log"oldPassword ",oldPassword);
    //console.log"newPassword ",newPassword);

    checkingNullValues();
  }

  //Method to checking invalid values of passwords
  const checkingNullValues=()=>{

    let result=true;
    if(oldPassword===''||oldPassword===' '||oldPassword===null||oldPassword===undefined)
    {
      toast.error("Enter Old Password First !!");
      result=false;
    }
    if(newPassword===''||newPassword===' '||newPassword===null||newPassword===undefined)
    {
      toast.error("Enter New Password First !!");
      result=false;
    }

    if(result===true)
    {
      hitRequestOnBackend();
      setDivState('block');
    }
  }

  //Method to hit request on backend
  const hitRequestOnBackend=()=>{
    let request={oldPassword:oldPassword,newPassword:newPassword};
    let promise=Post(updateCredentialsApi,request);
    promise.then(e=>{
      handleResponse(e);
    })
  }

  const handleResponse=(e)=>{
    //console.log"e ",e);
    setDivState('none');

    if(e.response==='error')
    {
      navigate("/error");
    }
    else if(e.response==='0')
    {
      toast.error("Something Went Wrong");
    }
    else if(e.response==='3')
    {
      toast.error("Invalid Format");
    }
    else if(e.response==='1')
    {
      toast.success(e.message);
    }
  }

  //Loader Stuff
  let Loader=require('react-loader');

  //Hook to store starting div state(loading div)
  const[divState,setDivState]=useState('none');

  //check token from local storage
  const checkToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===null||token===undefined||token===''||token===' ')
  {
    setTimeout(()=>{
      if(token===undefined || token==null ||token===''||token===' ')
      {
        navigate("/");
      }
    },1000/5);
  }

  return (
    <>
      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={false} options={options} className="spinner" />
      </div>

      {/* <!-- sec6  --> */}
      <ToastContainer/>
      <Header/>
      <Sidebar/>
      <div id="sixTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Change Password</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <div className="main-date-form">
            {/* <!-- user --> */}
            <div className="c-box">
              <div className="title-ic">
                <p>
                  <span>
                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                  </span>{" "}
                  <span>Change Password</span>
                </p>
              </div>

              {/* <!-- date --> */}
              <div className="date-inner">
                <div className="end-date">
                  <label htmlFor="pwd">Old Password:</label>
                  <input
                    type="password"
                    id="pwd"
                    name="pwd"
                    placeholder="Old Password"
                    autoComplete="off"
                    onChange={(e)=>{
                      setOldPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="end-date">
                  <label htmlFor="newpwd">New Password:</label>
                  <input
                    type="password"
                    id="newpwd"
                    name="pwd"
                    placeholder="New Password"
                    onChange={(e)=>{
                      setNewPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="end-date">
                  <label>(Add one character between A-Z, one a-z, one 0-9, one from @#$%^&-+= with no space & size b/w 8 to 15)</label>
                </div>
                <div className="date-search-btn">
                  <button className="bg-orange"
                    onClick={()=>{
                      handleChangeButton();
                    }}>
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
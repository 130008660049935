import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { subscribeApi } from "../Data/Api";
import Post from "../Requests/Post";
import { options } from "../Data/LoaderData";

let token;
const Activation = () => {

  //Calling Check Token Method
  useEffect(()=>{
    checkToken();
    // eslint-disable-next-line
  },[]);

  //to go on any other page
  const navigate=useNavigate();

  //Hook to store ani & pack
  const[ani,setAni]=useState('');
  const[pack,setPack]=useState('');

  //Method to handle activation button
  const handleActivationButton=()=>{
    checkingEmptyValues();
  }

  //Method to check empty values
  const checkingEmptyValues=()=>{

    let result=true;
    if(ani==='' || ani===' ' || ani===null || ani===undefined)
    {
      toast.error("Enter MSISDN First !!");
      result=false;
    }
    if(pack==='' || pack===' '|| pack===null || pack===undefined)
    {
      toast.error("Choose Pack First !!");
      result=false;
    }

    if(result===true)
    {
      hitOnBackend();
      setDivState('block');
    }
  }

  //Method to hit on backend for subscription
  const hitOnBackend=()=>{

    //Getting user from localStorage
    let userName=localStorage.getItem("user");

    let request={ani:ani,pack:pack,user:userName};
    //console.log("request ",request);

    let promise=Post(subscribeApi,request);
    promise.then(e=>{
      handleResponse(e);
    })
  }

  //Method to handle response
  const handleResponse=(e)=>{
    //console.log(e);
    setDivState('none');
    if(e.response==='error')
    {
      navigate("/error");
    }
    else if(e.response==='0')
    {
      toast.error("Something Went Wrong");
    }
    else if(e.response==='1')
    {
      toast.success(e.message);
    }
  }

  //Loader Stuff
  let Loader=require('react-loader');

  //Hook to store starting div state(loading div)
  const[divState,setDivState]=useState('none');

  //check token from local storage
  const checkToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===null||token===undefined||token===''||token===' ')
  {
    setTimeout(()=>{
      if(token===undefined || token==null ||token===''||token===' ')
      {
        navigate("/");
      }
    },1000/5);
  }

  return (
    <>

      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={false} options={options} className="spinner" />
      </div>

      {/* <!-- sec3 --> */}
      <ToastContainer/>
      <Header/>
      <Sidebar/>
      <div id="thirdTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Activation</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <div className="main-date-form">
            {/* <!-- user --> */}
            <div className="c-box">
              <div className="title-ic">
                <p>
                  <span>
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>{" "}
                  <span>Activation </span>
                </p>
              </div>

              {/* <!-- date --> */}
              <div className="date-inner">
                <div className="end-date">
                  <label htmlFor="name">Msisdn:</label>
                  <input
                    type="name"
                    id="name"
                    name="name"
                    placeholder="Msisdn"
                    autoComplete="off"
                    onChange={(e)=>{
                      setAni(e.target.value);
                    }}
                  />
                </div>
                <div className="end-date">
                  <label htmlFor="start">Pack:</label>
                  <select name="cars" id="cars" form="carform"
                    onChange={(e)=>{
                      setPack(e.target.value);
                    }}>
                      <option>Choose</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                  </select>
                </div>
                <div className="date-search-btn">
                  <button className="bg-orange" type="submit"
                    onClick={()=>{
                      handleActivationButton();
                    }}>
                    Activate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Activation;